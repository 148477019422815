body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  color: #262626;
}

.swal2-container {
  z-index: 1500 !important;
}

.swal2-actions {
  width: 100%;
} 

.swal2-actions button {
  width: 45%;
}

.swal2-confirm {
  background-color: #29166F !important;
  box-shadow: none !important;
}

.page-header-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.page-header-row .buttons-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.page-header-row .buttons-wrapper div {
  width: 150px;
}

.tabs-wrapper {
  width: 50%;
  position: relative;
  left: 25%;
}

@media screen and (max-width: 920px) {
  .page-header-row {
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
  }

  .page-header-row .buttons-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .page-header-row .buttons-wrapper div {
    width: 100%;
  }

  .tabs-wrapper {
    width: 100%;
    position: relative;
    left: 0%;
  }
}

.print-only {
  display: none; /* Hide the container by default */
}

.print-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two cards per row */
  grid-gap: 16px; /* Space between cards */
}

.card-wrapper {
  page-break-inside: avoid; /* Prevent cards from being split across pages */
}

@media print {
  .print-only {
    display: block; /* Show the container only when printing */
  }
  
  .print-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns for printing */
    grid-gap: 16px; /* Space between cards on print */
  }

  .card-wrapper {
    width: 100%;
    height: calc(50vh - 16px); /* Adjust card height to fit 4 cards per page */
    box-sizing: border-box; /* Include padding and border in size */
  }

  @page {
    size: A4; /* Specify paper size (A4 or Letter as needed) */
    margin: 0mm; /* Adjust page margins */
  }

  body {
    margin: 0;
    padding: 0;
  }
}
